import React, { useState, useContext, useRef, useEffect } from "react";
import "../styles/XContent.scss";
import { xList } from "../services/apiRequest.js";
import { Tweet } from "react-tweet";

const XContent = (props) => {
  const { LANG } = props;
  const abortController = useRef(new AbortController());
  const [tweets, setTweets] = useState([]);

  useEffect(() => {
    let newTweets = [];
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    script.async = true;
    document.body.appendChild(script);

    xList(LANG, null, (err, res) => {
      if (!err) {
        setTweets(res.result.flat());
      }
    });
  }, [LANG]);

  return (
    <>
      <div className="XContent">
        {tweets.map((url, index) => (
          <div data-theme="dark" className="XContent-content">
            <Tweet id={url} />
          </div>
        ))}
      </div>
    </>
  );
};

export default XContent;
