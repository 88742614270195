import React, { useState, useContext, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import "../styles/DrawCard.scss";
import XContent from "../components/XContent.js";

const DrawCard = (props) => {
  const navigate = useNavigate();
  const { LANG } = props;
  const intl = useIntl();

  const DrawCardXOnClick = () => {
    navigate("/landing2");
  };

  return (
    <>
      <div className="DrawCard_BG">
        <div className="DrawCard">
          <div className="DrawCard-content">
            <div className="DrawCard-x">
              <div className="DrawCard-x__img">
                <img
                  src={`${process.env.PUBLIC_URL}/imgs/X.svg`}
                  alt="X"
                  onClick={DrawCardXOnClick}
                />
              </div>
            </div>
            <img
              src={`${process.env.PUBLIC_URL}/imgs/card05.png`}
              className="DrawCard-content__historyLogo"
              alt=""
            />
            <div className="DrawCard-content__Xcontent">
              <XContent LANG={LANG} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DrawCard;
